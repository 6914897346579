import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';

const SortWisePOCReport = () => {
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [from, setFrom] = useState(dateFormat(new Date()));
  const [to, setTo] = useState(dateFormat(new Date()));
  const [pdfData, setPdfData] = useState({
    from: '',
    to: '',
    tableTitles: [],
    tableContents: [],
  });
  const filterColumns: Array<String> = ['Bale_count', 'Customer_Name', 'Customer_Sort', 'LMts'];
  const getLocaleNumber = (num) => {
    let currNum = +num;
    return currNum.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'Customer Name',
      selector: (row) => `${row.Customer_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.Customer_Sort}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bale Count',
      selector: (row) => `${row.Bale_count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'L.Mtrs',
      selector: (row) => `${row.LMts}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const pdfTableTitles = ['Customer Name', 'Customer Sort', 'Bale Count', 'L.Mtrs'];
  const getApi = async () => {
    showLoader();
    let res = await axios.get(`${baseUri()}grey-receipt-advice/sortwise-packing-report?from=${from}&to=${to}`);
    res = (await res.data.data?.result) || [];
    const pdfTableContents = [];
    const cottonContents = [];
    const cottonTotal = {
      'Bale Count': 0,
      'L.Mtrs': 0,
    };
    const mmfTotal = {
      'Bale Count': 0,
      'L.Mtrs': 0,
    };
    const mmfContents = [];
    res.forEach((row: any) => {
      if (row.cotton) {
        cottonContents.push([row.Customer_Name, row.Customer_Sort, row.Bale_count, getLocaleNumber(row.LMts)]);
        cottonTotal['Bale Count'] += +row.Bale_count;
        cottonTotal['L.Mtrs'] += +row.LMts;
      }
      if (row.mmf) {
        mmfContents.push([row.Customer_Name, row.Customer_Sort, row.Bale_count, getLocaleNumber(row.LMts)]);
        mmfTotal['Bale Count'] += +row.Bale_count;
        mmfTotal['L.Mtrs'] += +row.LMts;
      }
    });
    cottonContents.push(['', 'Total', cottonTotal['Bale Count'], getLocaleNumber(cottonTotal['L.Mtrs'])]);
    mmfContents.push(['', 'Total', mmfTotal['Bale Count'], getLocaleNumber(mmfTotal['L.Mtrs'])]);
    pdfTableContents.push(['Cotton']);
    pdfTableContents.push(...cottonContents);
    pdfTableContents.push(['', '', '', '']);
    pdfTableContents.push(['MMF']);
    pdfTableContents.push(...mmfContents);
    setPdfData({
      from: new Date(from).toLocaleDateString('en-IN'),
      to: new Date(to).toLocaleDateString('en-IN'),
      tableTitles: pdfTableTitles,
      tableContents: pdfTableContents,
    });
    setTableData(res);
    setItemData(res);
    hideLoader();
  };

  return (
    <>
      <SEO title="Sortwise Packing Report" />
      <Card>
        <header>Sortwise Packing Report</header>
        <CardBody>
          <Row>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                From Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="from"
                  value={from}
                  placeholder="From Date"
                  onChange={(e) => setFrom(e.target.value)}
                  className="from require"
                />
              </InputGroup>
            </Col>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                To Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="to"
                  value={to}
                  placeholder="To Date"
                  onChange={(e) => setTo(e.target.value)}
                  className="to require"
                />
              </InputGroup>
            </Col>
          </Row>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>Sortwise Packing Report</header>
        <CardBody>
          {tableData.length > 0 && <PdfGenerator documentTitle="sortwise-packing-report" data={pdfData} />}
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default SortWisePOCReport;
